@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
.App {
  min-width: 100%;
  min-height: 100vh;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.carousel.carousel-slider {
  overflow: unset !important;
}

.control-dots {
  bottom: -3rem !important;
}

.p-rating.p-rating-readonly .p-rating-icon {
  color: #1e1e1e !important;
}

.carousel .slide img,
.carousel img {
  width: auto !important;
}

.stars-rating-testimonial.p-rating.p-rating-readonly span {
  color: #ff900e !important;
}

.p-accordion-header-link {
  background-color: transparent !important;
  font-weight: 400 !important;
}

.p-accordion-tab {
  margin: 1rem 0 !important;
}

.user-details .p-accordion-tab {
  margin: 0 !important;
}

.p-datepicker-trigger,
.p-datepicker-trigger:hover,
.p-datepicker-trigger:focus,
.p-datepicker-trigger:active {
  border-color: #ced4da !important;
  background-color: transparent !important;
  border-right: none !important;
}

.input-product-calendar,
.input-product-calendar:focus,
.input-product-calendar:active {
  border-left: none !important;
  border-color: #ced4da !important;
}

.user-details-content .p-accordion-content,
.user-details .p-accordion-tab .p-accordion-header .p-accordion-header-link {
  border: none !important;
  color: #4b0daf !important;
  font-weight: 700 !important;
}
